<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <h2 class="title-style pt-3">
      Account Companies 🏢
    </h2>
    <p class="mt-0 mb-0 sub-title-style">
      Explore Companies and Details here.
    </p>
    <v-card
      color="#fff"
      class="project-list-card"
    >
      <br>
      <v-col
        class="text-right table-top-border"
      >
        <v-btn
          v-if="partnerCheck()"
          text
          outlined
          class="mr-2"
          @click="syncCompaniesFromPartner()"
        >
          <v-icon
            color="primary"
            size="30"
            left
          >
            mdi-cloud-sync-outline
          </v-icon>
          <span class="sync-btn"> Sync Autodesk </span>
        </v-btn>
        <v-btn
          color="#000"
          @click="showForm = true"
        >
          <v-icon class="mr-2">
            mdi-plus-circle-outline
          </v-icon>
          Add Company
        </v-btn>
      </v-col>
      <v-card-title class="filter-card">
        <v-row>
          <v-col
            cols="12"
            lg="4"
            md="5"
            sm="12"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <p
        v-if="partnerCheck()"
        class="info-text"
      >
        *Indicates from partner account
      </p>
      <v-data-table
        :headers="headers"
        :items="companiesList"
        :search="search"
        class="elevation-1 table-projects"
        disable-sort
        mobile-breakpoint="100"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td
              class="more-link"
              @click="editCompanyDetails(item.id)"
            >
              {{ item.name }}<span
                v-if="item.database === false"
                class="partner-company"
              >*</span>
            </td>
            <td>
              <span v-if="item.description !== null && item.description !== ''">
                {{ item.description }}
              </span>
              <span v-else>
                N/A
              </span>
            </td>
            <td>{{ item.trade }}</td>
            <td>
              <span v-if="isCountryAvailable(item.address)">
                {{ item.address.country }}
              </span>
              <span v-else>
                N/A
              </span>
            </td>
            <td>{{ item.created_at | convertToLocal }}</td>
            <td>
              <v-btn
                class="mt-n2 action-btn"
                elevation="1"
                fab
                color="#37474F"
                x-small
                icon
                @click="editCompanyDetails(item.id)"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </td>
            <td>
              <v-col>
                <v-btn
                  class="mt-n2 action-btn"
                  elevation="1"
                  fab
                  x-small
                  icon
                  color="#C62828"
                  @click.stop
                  @click="deleteCompany(item.id, item.project_status)"
                >
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </v-col>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="py-3" />
    </v-card>
    <create-company
      v-if="showForm"
      @closed="showForm = false"
      @company-created="getCompaniesList();"
    />
    <edit-company-details
      v-if="showEditForm"
      :company-id="companyId"
      @closed="showEditForm = false"
      @company-details-updated="getCompaniesList()"
    />
    <partner-auth-check
      ref="partnerAuth"
      @partner-authorized="getCompaniesFromPartner"
    />
    <centre-spinner
      :loading="loading"
    />
    <overlay-alert
      :show-progress="showProgress"
      :process-alert="processAlert"
    />
    <delete-company
      v-if="showDelete"
      :title="'Delete this company ?'"
      :message="'Action will permanently remove the company and all its assigned App Users, Do you want to continue ?'"
      @closed="showDelete = false"
      @confirmed="deleteConfirmed()"
    />
    <company-sync-alert
      v-if="showSyncAlert"
      :title="'No hubs found ?'"
      :message="'Please sync your projects first before getting your account companies. Sync projects now ?'"
      :cancel-button="'No'"
      :confirm-button="'Yes'"
      @closed="showSyncAlert = false"
      @confirmed="routeToProjects"
    />
  </v-container>
</template>
<script>
  import CreateCompany from 'src/views/dashboard/companies/CreateCompany';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import EditCompanyDetails from 'src/views/dashboard/companies/EditCompanyDetails';
  import moment from 'moment';
  import PartnerAuthCheck from 'src/views/dashboard/partnerauth/PartnerAuthCheck';
  import global from 'src/mixins/global';
  import OverlayAlert from 'src/views/dashboard/component/OverlayAlert';
  import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
  import Constants from 'src/constants';

  export default {
    name: 'CompaniesList',
    components: {
      'create-company': CreateCompany,
      'centre-spinner': spinner,
      'edit-company-details': EditCompanyDetails,
      'partner-auth-check': PartnerAuthCheck,
      'overlay-alert': OverlayAlert,
      'company-sync-alert': DeleteDialog,
      'delete-company': DeleteDialog,
    },
    filters: {
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
    },
    mixins: [global],
    data () {
      return {
        showForm: false,
        loading: false,
        headers: [
          { text: 'Company Name', align: 'start', value: 'name' },
          { text: 'Description', value: 'description' },
          { text: 'Trade', value: 'trade' },
          { text: 'Country', value: 'country' },
          { text: 'Added On', value: 'created_at' },
          { text: 'More', value: 'edit' },
          { text: 'Delete', value: 'delete' },
        ],
        search: '',
        companyId: '',
        showEditForm: false,
        showProgress: false,
        processAlert: '',
        showDelete: false,
        showSyncAlert: false,
      };
    },
    computed: {
      companiesList () {
        return this.$store.getters['companies/getCompaniesList'];
      },
      projectsList () {
        return this.$store.getters['projects/getProjectsList'];
      },
      isProjectsFromPartnerExist () {
        if (this.projectsList.length > 0 && this.projectsList.some(item => item.database === false)) {
          return true;
        }
        return false;
      },
    },
    async mounted () {
     await this.getCompaniesList();
     await this.getProjectsList();
    },
    methods: {
      async getCompaniesList () {
        this.loading = true;
        await this.$store.dispatch('companies/fetchCompaniesList').catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
      editCompanyDetails (companyId) {
        this.companyId = companyId;
        this.showEditForm = true;
      },
      syncCompaniesFromPartner () {
        if (this.isProjectsFromPartnerExist === false) {
          this.showSyncAlert = true;
          return;
        }
        this.$refs.partnerAuth.isPartnerAuthorized();
      },
      async getCompaniesFromPartner () {
        this.showProgress = true;
        this.processAlert = 'Syncing...';
        await this.$store.dispatch('partners/fetchCompaniesFromPartner').then(response => {
          this.showProgress = false;
          this.getCompaniesList();
        }).catch(() => {
          this.showProgress = false;
        });
      },
      deleteCompany (id, projectStatus) {
        if (projectStatus === true) {
          this.$store.dispatch('alert/onAlert', {
            message: 'This company is linked to a project',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.companyId = id;
        this.showDelete = true;
      },
      deleteConfirmed () {
        this.loading = true;
        this.$store.dispatch('companies/deleteCompany', {
          companyId: this.companyId,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Company deleted successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.showDelete = false;
          this.loading = false;
          this.getCompaniesList();
        }).catch(() => {
          this.loading = false;
        });
      },
      isCountryAvailable (address) {
        if (address === null || address.country === null) {
          return false;
        }
        return true;
      },
      async getProjectsList () {
        this.loading = true;
        await this.$store.dispatch('projects/fetchProjectsList').catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
      routeToProjects () {
        this.$router.push({ name: 'Projects' });
      },
    },
  };
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.title-style {
  color: #37474F;
  font-size: 36px;
  font-weight: 600;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.sync-btn {
  color: #000;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
  text-transform: capitalize;
  margin-top: 3px;
  margin-left: 5px;
  font-size: 15px;
}
.partner-company {
  color: #FF3700;
  font-weight: bold;
}
.info-text {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  font-style: italic;
  color: #37474F;
  margin-left: 15px;
  margin-top: -20px;
}
.more-link{
  cursor: pointer;
}
.table-projects {
  border-radius: 0px !important;
  background: #fff;
  box-shadow: none !important;
  border: 1px solid #ccc;
  border-top: none;
}
.project-list-card {
  box-shadow: none !important;
}
.table-projects tbody tr:nth-of-type(even) {
  background: #fff !important;
}
.table-projects tbody tr:nth-of-type(odd):hover {
  background: #fff !important;
}
.action-btn {
  background: #fff !important;
}
.filter-card {
  border: 1px solid #ccc !important;
  padding: 15px;
}
.table-top-border{
  border: 1px solid #ccc;
  border-bottom: none;
  background: #edf6f9;
}
.info-text{
  margin-bottom: 0px !important;
}
.sub-title-style {
  color: #aaa;
}
</style>
