<template>
  <v-dialog
    v-model="showModal"
    max-width="700"
  >
    <v-card
      outlined
      color="#fff"
    >
      <br>
      <h2 class="title-style">
        <v-icon
          class="icon-style"
          left
        >
          mdi-pencil
        </v-icon>Update Details
      </h2>
      <v-container fluid>
        <v-alert
          v-if="disableEditButton"
          text
          prominent
          icon="mdi-information-symbol"
          color="#01579B"
          border="left"
          class="authorization-alert"
        >
          EzyBuild does not support editing imported data. Please make changes to your Autodesk account and resync.
        </v-alert>
      </v-container>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-container fluid>
          <v-card-text class="text-body-1 text-center">
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="name"
                  label="Company Name*"
                  dense
                  outlined
                  :rules="[(v) => !!v || 'This field is required']"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-autocomplete
                  v-model="companyTrade"
                  :items="trades"
                  label="Trade*"
                  dense
                  outlined
                  single-line
                  :rules="[(v) => !!v || 'This field is required']"
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="description"
                  filled
                  auto-grow
                  rows="2"
                  label="Descripion"
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <address-form
                  ref="addressForm"
                  class="address-form"
                  @addressEntered="assiginAddress($event)"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="phoneNumber"
                  label="Phone Number*"
                  dense
                  outlined
                  :rules="[(v) => !!v || 'This field is required']"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="websiteUrl"
                  label="Website URL"
                  dense
                  outlined
                />
              </v-col>
              <br>
              <p class="text-sm-left">
                *Indicates required field
              </p>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="#000"
              :disabled="disableEditButton"
              @click="updateCompanyDetails()"
            >
              Update
            </v-btn>
            <v-btn
              class="mt-2"
              color="#aaa"
              @click="showModal = false"
            >
              <span class="black--text">Cancel</span>
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
      <centre-spinner
        :loading="loading"
      />
    </v-card>
  </v-dialog>
</template>
<script>
  import Constants from 'src/constants';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import AddressForm from 'src/views/dashboard/component/AddressForm';

  export default {
    name: 'EditCompanyDetails',
    components: {
      'centre-spinner': spinner,
      'address-form': AddressForm,
    },
    props: {
      companyId: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        showModal: true,
        loading: false,
        valid: false,
        name: '',
        abnNumber: null,
        companyTrade: '',
        address: {},
        phoneNumber: null,
        websiteUrl: null,
        description: '',
        disableEditButton: false,
      };
    },
    computed: {
      trades () {
        return this.$store.getters['companies/getCompanyTradesList'];
      },
      companyDetails () {
        return this.$store.getters['companies/getCompanyDetails'];
      },
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    async mounted () {
      this.loading = true;
      await this.getCompanyTradesList();
      await this.getCompanyDetails();
      this.loading = false;
    },
    methods: {
      async getCompanyDetails () {
        await this.$store.dispatch('companies/fetchCompanyDetails', {
          companyId: this.companyId,
        }).then(response => {
          this.name = this.companyDetails.name;
          this.companyTrade = this.companyDetails.trade;
          this.description = this.companyDetails.description;
          this.$refs.addressForm.fillData(this.companyDetails.address);
          this.phoneNumber = this.companyDetails.phone_number;
          this.websiteUrl = this.companyDetails.website;
          if (this.companyDetails.database === false) {
            this.disableEditButton = true;
            return;
          }
          this.disableEditButton = false;
        });
      },
      async getCompanyTradesList () {
        await this.$store.dispatch('companies/fetchCompanyTradesList');
      },
      assiginAddress (address) {
        this.address = address;
      },
      updateCompanyDetails () {
        if (this.$refs.form.validate() === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please fill the required fields before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.loading = true;
        this.$refs.addressForm.getAddress();
        this.$store.dispatch('companies/updateCompanyDetails', {
          companyId: this.companyId,
          updateDetails: {
            name: this.name,
            abn_number: this.abnNumber,
            trade: this.companyTrade,
            address: this.address,
            description: this.description,
            website: this.websiteUrl,
            phone_number: this.phoneNumber,
          },
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Comapny details updated successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.$emit('company-details-updated');
          this.loading = false;
          this.showModal = false;
        }).catch(() => {
          this.loading = false;
        });
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.doc-btn-style {
    background-color: #455a64 !important;
}
.subheader-style {
  color: #78909C;
  font-size: 18px;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-weight: bold;
  text-align: center;
  margin-top: 35px;
  margin-bottom: 0px;
}
.my-note-style {
  font-size: 14px;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-weight: bold;
  color: #FF3700;
  margin-bottom: 20px;
}
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: -10px;
  padding-bottom: 10px;
  padding-top: 10px;
}
.address-form{
  border-left-color: #000 !important;
}
</style>
